<template>
  <b-modal id="contractGroupModal" :title="title" size="xl" @show="onShow">
    <b-form @submit.stop.prevent="">
      <b-form-row>
        <b-col cols="4">
          <label>Gruppenname:</label>
          <b-form-input v-model="groupName" placeholder="Gruppenname..." trim/>
        </b-col>
      </b-form-row>
      <b-form-row class="mt-2">
        <b-col>
          <label>Notiz:</label>
          <b-form-textarea v-model="note" rows="12"/>
        </b-col>
      </b-form-row>
    </b-form>
    <template #modal-footer="{ cancel }">
      <b-button v-if="method === 'edit' && !data.inactive" variant="outline-danger" class="mr-auto" @click="toggleGroup()">
        <b-spinner v-show="loadingActiveButton" small/>
        Gruppe inaktiv setzen
      </b-button>
      <b-button v-else-if="method === 'edit' && data.inactive" variant="outline-success" class="mr-auto"
                @click="toggleGroup()">
        <b-spinner v-show="loadingActiveButton" small/>
        Gruppe aktiv setzen
      </b-button>
      <b-button :variant="variantDanger" @click="cancel()">Abbrechen</b-button>
      <b-button :variant="variantSuccess" @click="ok" :disabled="disabledButton">
        <b-spinner v-show="loadingOkButton" small/>
        OK
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {mapGetters} from "vuex";
import {putRequest} from "@/modules/requests";

export default {
  name: "ContractGroupModal",
  props: ['method', 'data'],
  data() {
    return {
      id: null,
      groupName: '',
      note: '',
      loadingOkButton: false,
      loadingActiveButton: false
    }
  },
  watch: {
    method() {
      this.onShow()
    },
    data() {
      this.onShow()
    }
  },
  methods: {
    ok() {
      switch(this.method) {
        case 'edit':
          this.updateGroup();
          break;
        case 'add':
          this.addGroup();
          break;
      }
    },
    onShow() {
      if(this.method === 'edit' && this.data) {
        this.id = this.data.id
        this.groupName = this.data.displayname
        this.note = this.data.note
      }
      else {
        this.id = null
        this.groupName = ''
        this.note = ''
      }
    },
    refresh() {
      this.$emit('refresh');
    },
    async addGroup() {
      this.loadingOkButton = true
      let data = {
        displayname: this.groupName,
        note: this.note
      }
      await putRequest('contracts/fg/group/add', data, this, 'Neuer Eintrag wurde erfolgreich erstellt.', 'Neuer Eintrag konnte nicht erstellt werden.')
          .then(() => {
            this.loadingOkButton = false
            this.refresh()
            this.$bvModal.hide('contractGroupModal')
          })
          .catch(() => {
            this.loadingOkButton = false
          })
    },
    async updateGroup() {
      this.loadingOkButton = true
      let data = {
        group_id: this.id,
        displayname: this.groupName,
        note: this.note
      }
      await putRequest('contracts/fg/group/update', data, this, 'Der Eintrag wurde erfolgreich aktualisiert.', 'Der Eintrag konnte nicht aktualisiert werden.')
          .then(() => {
            this.loadingOkButton = false
            this.refresh()
            this.$bvModal.hide('contractGroupModal')
          })
          .catch(() => {
            this.loadingOkButton = false
          })
    },
    async toggleGroup() {
      this.loadingActiveButton = true
      await putRequest('contracts/fg/group/inactive-toggle/' + this.data.id, null, this, 'Der Eintrag wurde erfolgreich umgesetzt.', 'Der Eintrag konnte nicht umgesetzt werden.')
          .then(() => {
            this.loadingActiveButton = false
            this.refresh()
            this.$bvModal.hide('contractGroupModal')
          })
          .catch(() => {
            this.loadingActiveButton = false
          })
    }
  },
  computed: {
    ...mapGetters(['userThemeId']),
    ...mapGetters(['userThemeId']),
    iconVariant() {
      if(this.userThemeId === 2) { return 'dark' }
      return 'white'
    },
    title() {
      if(this.method === 'edit') {
        return 'Gruppe ändern'
      }
      return 'Gruppe hinzufügen'
    },
    disabledButton() {
      return !(this.groupName)
    },
    variantSuccess() {
      if(this.userThemeId === 2) { return 'success-deuteranopia' }
      return 'success'
    },
    variantDanger() {
      if(this.userThemeId === 2) { return 'danger-deuteranopia' }
      return 'danger'
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
