<template>
  <b-modal id="contractModal" :title="title" size="xl" @show="onShow">
    <b-form @submit.stop.prevent="">
      <b-form-row>
        <b-col cols="6">
          <label>Vertragsname:</label>
          <b-form-input v-model="contractName" placeholder="Vertragsname..." trim/>
        </b-col>
        <b-col cols="2">
          <label>Inaktiv: <b-icon-question-circle-fill id="tt-active" :variant="iconVariant" class="header-icon"/></label>
          <b-form-checkbox class="ml-1 mt-1" v-model="inactive" :value="true" :unchecked-value="false"/>
          <b-tooltip target="tt-active" triggers="hover">Inaktiver Vertrag</b-tooltip>
        </b-col>
      </b-form-row>
      <b-form-row class="mt-2">
        <b-col cols="3">
          <label>Gruppe:</label>
          <b-form-select v-model="groupSelect.selected" :options="groupSelect.options">
            <template #first>
              <b-form-select-option :value="null" disabled>-- Gruppe auswählen --</b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col cols="3">
          <label>Anzahl: <b-icon-question-circle-fill id="tt-count" :variant="iconVariant" class="header-icon"/></label>
          <b-form-input v-model="count" number type="number" :disabled="this.infinite"/>
          <b-tooltip target="tt-count" triggers="hover">Anzahl Lizenzen</b-tooltip>
        </b-col>
        <b-col cols="2">
          <label>Unbegrenzt: <b-icon-question-circle-fill id="tt-infinite" :variant="iconVariant" class="header-icon"/></label>
          <b-form-checkbox class="ml-1 mt-1" v-model="infinite" :value="true" :unchecked-value="false"/>
          <b-tooltip target="tt-infinite" triggers="hover">Keine Begrenzung der Anzahl</b-tooltip>
        </b-col>
      </b-form-row>
      <b-form-row class="mt-2">
        <b-col cols="3">
          <label>Dauer:</label>
          <b-form-input v-model="duration" placeholder="Vertragsdauer..." trim/>
        </b-col>
        <b-col cols="3">
          <label>Auslaufdatum:</label>
          <b-input-group>
            <b-form-input v-model="expire" @input="inputChangeExpire" type="text"/>
            <b-input-group-append>
              <b-form-datepicker v-model="datepickerDateExpire" @input="datepickerChangeExpire" button-only dropleft locale="de"/>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col cols="3">
          <label>Gekündigt zum:</label>
          <b-input-group>
            <b-form-input v-model="terminatedFor" @input="inputChangeTerminated" type="text"/>
            <b-input-group-append>
              <b-form-datepicker v-model="datepickerDateTerminated" @input="datepickerChangeTerminated" button-only dropleft locale="de"/>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-form-row>
      <b-form-row class="mt-2">
        <b-col>
          <label>Notiz:</label>
          <b-form-textarea v-model="note" rows="4"/>
        </b-col>
      </b-form-row>
    </b-form>
    <template #modal-footer="{ cancel }">
      <b-button :variant="variantDanger" @click="cancel()">Abbrechen</b-button>
      <b-button :variant="variantSuccess" @click="ok" :disabled="disabledButton">
        <b-spinner v-show="loadingOkButton" small/>
        OK
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import dayjs from "dayjs";
import {mapGetters} from "vuex";
import {getRequest, putRequest} from "@/modules/requests";

export default {
  name: "ContractModal",
  props: ['method', 'data', 'groupId'],
  data() {
    return {
      id: null,
      contractName: '',
      count: 0,
      duration: '',
      note: '',
      infinite: false,
      inactive: false,
      expire: dayjs().format('DD.MM.YYYY'),
      datepickerDateExpire: dayjs().format('YYYY-MM-DD'),
      terminatedFor: dayjs().format('DD.MM.YYYY'),
      datepickerDateTerminated: dayjs().format('YYYY-MM-DD'),
      groupSelect: {
        selected: null,
        options: []
      },
      loadingOkButton: false
    }
  },
  watch: {
    data() {
      this.onShow()
    },
    method() {
      this.onShow()
    },
    groupId() {
      this.onShow()
    }
  },
  methods: {
    ok() {
      switch(this.method) {
        case 'edit':
          this.updateContract();
          break;
        case 'add':
          this.addContract();
          break;
      }
    },
    refresh() {
      this.$emit('refresh');
    },
    inputChangeExpire(event) {
      if(dayjs(event, 'DD.MM.YYYY', true).isValid()) {
        this.datepickerDateExpire = dayjs(event, 'DD.MM.YYYY', true).format('YYYY-MM-DD')
      }
    },
    datepickerChangeExpire(event) {
      if(dayjs(event, 'YYYY-MM-DD', true).isValid()) {
        this.expire = dayjs(event).format('DD.MM.YYYY')
      }
    },
    inputChangeTerminated(event) {
      if(dayjs(event, 'DD.MM.YYYY', true).isValid()) {
        this.datepickerDateTerminated = dayjs(event, 'DD.MM.YYYY', true).format('YYYY-MM-DD')
      }
    },
    datepickerChangeTerminated(event) {
      if(dayjs(event, 'YYYY-MM-DD', true).isValid()) {
        this.terminatedFor = dayjs(event).format('DD.MM.YYYY')
      }
    },
    async onShow() {
      await this.getGroups()
      if(this.method === 'edit' && this.data) {
        this.id = this.data.id
        this.note = this.data.note
        this.count = this.data.count
        this.infinite = this.data.infinite
        this.duration = this.data.duration
        this.contractName = this.data.displayname
        this.groupSelect.selected = this.data.groupId
        this.inactive = (this.data) ? this.data.inactive : false
        this.expire = (this.data.expire) ? dayjs(this.data.expire).format('DD.MM.YYYY') : dayjs().format('DD.MM.YYYY')
        this.datepickerDateExpire = (this.data.expire) ? dayjs(this.data.expire).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD')
        this.terminatedFor = (this.data.terminated_for) ? dayjs(this.data.terminated_for).format('DD.MM.YYYY') : null
        this.datepickerDateTerminated = (this.data.terminated_for) ? dayjs(this.data.terminated_for).format('YYYY-MM-DD') : null
      }
      else {
        this.id = null
        this.contractName = ''
        this.count =  0
        this.infinite = false
        this.duration = ''
        this.inactive = false
        this.note = ''
        this.expire = dayjs().format('DD.MM.YYYY')
        this.datepickerDateExpire = dayjs().format('YYYY-MM-DD')
        this.terminatedFor = null
        this.datepickerDateTerminated = null
        this.groupSelect.selected = this.groupId
      }
    },
    async getGroups() {
      await getRequest('contracts/fg/contract-groups', null, this)
          .then((response) => {
            this.groupSelect.options = []
            response.data.forEach(element => {
              this.groupSelect.options.push({
                value: element.id,
                text: element.displayname
              })
            })
          })
    },
    async addContract() {
      this.loadingOkButton = true
      let data = {
        displayname: this.contractName,
        count: this.count,
        infinite: this.infinite,
        duration: this.duration,
        expire: this.expire,
        terminated_for: this.terminatedFor,
        note: this.note,
        inactive: this.inactive,
        group_id: this.groupSelect.selected
      }
      await putRequest('contracts/fg/add', data, this, 'Neuer Eintrag wurde erfolgreich erstellt.', 'Neuer Eintrag konnte nicht erstellt werden.')
          .then(() => {
            this.loadingOkButton = false
            this.refresh()
            this.$bvModal.hide('contractModal')
          })
          .catch(() => {
            this.loadingOkButton = false
          })
    },
    async updateContract() {
      this.loadingOkButton = true
      let data = {
        contract_id: this.id,
        displayname: this.contractName,
        count: this.count,
        infinite: this.infinite,
        duration: this.duration,
        expire: this.expire,
        terminated_for: this.terminatedFor,
        note: this.note,
        inactive: this.inactive,
        group_id: this.groupSelect.selected
      }
      await putRequest('contracts/fg/update', data, this, 'Der Eintrag wurde erfolgreich aktualisiert.', 'Der Eintrag konnte nicht aktualisiert werden.')
          .then(() => {
            this.loadingOkButton = false
            this.refresh()
            this.$bvModal.hide('contractModal')
          })
          .catch(() => {
            this.loadingOkButton = false
          })
    }
  },
  computed: {
    ...mapGetters(['userThemeId']),
    ...mapGetters(['userThemeId']),
    iconVariant() {
      if(this.userThemeId === 2) { return 'dark' }
      return 'white'
    },
    title() {
      if(this.method === 'edit') {
        return 'Vertrag ändern'
      }
      return 'Vertrag hinzufügen'
    },
    disabledButton() {
      return !(this.contractName && this.duration && this.expire && this.groupSelect.selected)
    },
    variantSuccess() {
      if(this.userThemeId === 3) { return 'success-deuteranopia' }
      return 'success'
    },
    variantDanger() {
      if(this.userThemeId === 2) { return 'danger-deuteranopia' }
      return 'danger'
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
